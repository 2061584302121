import {HomePageProps} from "@blogs/pages";
import {SiteIndexHelpers} from "@blogs/deploy";
import {MarketStatusClient} from "../components/MarketStatus.client";
import {PageSEO} from "@blogs/blog-components";

export const getStaticProps = SiteIndexHelpers.buildGetStaticProps({types: ['posts']})

const Page = (props: HomePageProps) => (
  <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
    <PageSEO
      title='Is The Stock Market Open? - Real-Time Market Status & Countdown'
      description='Stay updated with real-time information on whether the stock market is open or closed. Benefit from our accurate countdown timer for market open times and never miss a trading opportunity.'
    />
    <MarketStatusClient/>
  </div>
)

export default Page
