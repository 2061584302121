// @ts-nocheck
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string | number; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** Date custom scalar type */
  Date: { input: any; output: any; }
};

/** Market data  */
export type Alpaca = {
  __typename?: 'Alpaca';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  nextClose?: Maybe<Scalars['Date']['output']>;
  nextOpen?: Maybe<Scalars['Date']['output']>;
  status: MarketStatus;
};

export enum MarketStatus {
  closed = 'closed',
  open = 'open'
}

export type Query = {
  __typename?: 'Query';
  marketData: Alpaca;
};


export const GetMarketDataDocument = gql`
    query GetMarketData {
  marketData {
    status
    nextClose
    nextOpen
  }
}
    `;

/**
 * __useGetMarketDataQuery__
 *
 * To run a query within a React component, call `useGetMarketDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMarketDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMarketDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMarketDataQuery(baseOptions?: Apollo.QueryHookOptions<GetMarketDataQuery, GetMarketDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMarketDataQuery, GetMarketDataQueryVariables>(GetMarketDataDocument, options);
      }
export function useGetMarketDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMarketDataQuery, GetMarketDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMarketDataQuery, GetMarketDataQueryVariables>(GetMarketDataDocument, options);
        }
export type GetMarketDataQueryHookResult = ReturnType<typeof useGetMarketDataQuery>;
export type GetMarketDataLazyQueryHookResult = ReturnType<typeof useGetMarketDataLazyQuery>;
export type GetMarketDataQueryResult = Apollo.QueryResult<GetMarketDataQuery, GetMarketDataQueryVariables>;