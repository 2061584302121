'use client'
import {MarketStatus, useGetMarketDataQuery} from "../generated/types-and-hooks";
import {apolloClient} from "../apolloClient";
import {useEffect, useState} from "react";
import Head from "next/head";

type CountdownProps = {
  date: Date
  title: string
  refetch: any
}

const initialState = {days: 0, hours: 0, minutes: 0, seconds: 0}

const Countdown = (props: CountdownProps) => {
  const {date, title, refetch} = props
  const [{days, hours, minutes, seconds}, setState] = useState(() => (initialState))

  useEffect(() => {
    const update = () => {
      // Get current date and time
      const now = new Date().getTime();
      // Time to the date
      const timeToDate = new Date(date).getTime() - now;
      // Time calculations for days, hours, minutes and seconds
      const days = Math.floor(timeToDate / (1000 * 60 * 60 * 24));
      const hours = Math.floor((timeToDate % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((timeToDate % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeToDate % (1000 * 60)) / 1000);

      setState({
        days,
        hours,
        minutes,
        seconds
      })
    }

    update()

    const interval = setInterval(update, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [date])

  if (days + hours + minutes + seconds < 0) {
    setState(initialState)
    refetch?.()
    return null
  }

  return (
    <div className='flex flex-col'>
      <span className='mb-2'>{title}</span>

      <div className="text-6xl text-center flex w-full items-center justify-center">
        <div className="w-24 mx-1 p-2 bg-white dark:bg-black rounded-lg">
          <div className="leading-none">{days}</div>
          <div className="uppercase text-sm leading-none">Days</div>
        </div>
        <div className="w-24 mx-1 p-2 bg-white dark:bg-black rounded-lg">
          <div className="leading-none">{hours}</div>
          <div className="uppercase text-sm leading-none">Hours</div>
        </div>
        <div className="w-24 mx-1 p-2 bg-white dark:bg-black rounded-lg">
          <div className="leading-none">{minutes}</div>
          <div className="uppercase text-sm leading-none">Minutes</div>
        </div>
        <div className="w-24 mx-1 p-2 bg-white dark:bg-black rounded-lg">
          <div className="leading-none">{seconds}</div>
          <div className="uppercase text-sm leading-none">Seconds</div>
        </div>
      </div>
    </div>
  )
}

export const MarketStatusClient = () => {
  const {loading, data, startPolling, stopPolling, error, refetch} = useGetMarketDataQuery({
    client: apolloClient,
    fetchPolicy: "cache-and-network"
  })

  const {nextOpen, nextClose, status} = data?.marketData ?? {}

  useEffect(() => {
    startPolling(1000 * 60 * 60) // refresh once per hour

    return () => {
      stopPolling()
    }
  }, [startPolling, stopPolling])

  // refetch the data when the market opens or closes
  useEffect(() => {
    let timeoutMS = -1

    if (status === MarketStatus.closed) {
      timeoutMS = new Date(nextOpen).getTime() - new Date().getTime()
    } else if (status === MarketStatus.open) {
      timeoutMS = new Date(nextClose).getTime() - new Date().getTime()
    }

    if (timeoutMS > 0) {
      const timeout = setTimeout(refetch, timeoutMS + 1000)

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [nextOpen, nextClose, status, refetch])

  let content = null
  let favicon = '/static/favicons/favicon-32x32.png'

  if (error) {
    content = (
      <div>
        Could not load the market status.
      </div>
    )
  }

  if (loading) {
    content = (
      <div role="status">
        <svg aria-hidden="true" className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
             viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor"/>
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentFill"/>
        </svg>
        <span className="sr-only">Loading...</span>
      </div>
    )
  }

  if (status === MarketStatus.closed) {
    favicon = '/static/favicons/favicon-offline.png'
    content = (
      <div className='text-center'>
        <h2 className='text-3xl font-bold mb-10'>The stock market is <span className='text-red-800'>closed</span>.</h2>
        <Countdown refetch={refetch} title='It will open in' date={nextOpen}/>
      </div>
    )
  } else if (status === MarketStatus.open) {
    favicon = '/static/favicons/favicon-online.png'
    content = (
      <div className='text-center'>
        <h2 className='text-3xl font-bold'>The stock market is <span className='text-green-800'>open</span>!</h2>
        <Countdown refetch={refetch} title='It will close in' date={nextClose}/>
      </div>
    )
  }

  return (
    <div>
      <Head>
        <link key='favicon' rel="icon" type="image/png" sizes="32x32" href={favicon}/>
      </Head>
      {content}
    </div>
  )
}
