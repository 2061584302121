import {ApolloClient, InMemoryCache} from '@apollo/client';

const uri = process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT

console.log({graphql: uri})

if (!uri) {
  console.warn('Missing graphql endpoint!')
}

export const apolloClient = new ApolloClient({
  uri,
  cache: new InMemoryCache(),
});
